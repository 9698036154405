// Step 1: Import React
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Layout} from "../../components/Layout"
import Button from '../../components/buttons/button'
import ProductBanner from "../../components/product_banners/product_banners";
import SlickProduct from "../../components/product_slick/slick_product";
import Faq from "../../components/faq/faq";
import Banner from "../../imgs/bg_opciones.jpg";
import IframeVIdeo from "../../components/iframe_video/iframe_video";
import faqjson from "../../misc/opciones_related/faq.json";
import slick_items from "../../misc/opciones_related/slick_items_opciones.json";
import slick_items2 from "../../misc/opciones_related/slick_items_operaciones.json";
import {SalWrapper} from "react-sal";
import { apiRequest } from "../../apiRquest/apiRequest";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery, Link} from "gatsby";

const pageTitle = 'Invertí en Opciones Financieras | Balanz';
const pageDesc = 'Invertí en Opciones Financieras y hacé crecer tu capital. Con Balanz tus ahorros pueden rendir más. Contamos con la mejor plataforma de inversiones.';

let text = `Creemos que existen tantas inversiones como inversores, <br/><span class='highlightsbold'> te presentamos Opciones.</span>`;

const OpcionesPage = () => {
  //const [mobile, setMobile] = useState(false);
    const [xs, setXs] = useState(false);
    const FaqsData = useStaticQuery(graphql`
        {
            allFaqs(
                filter: {category_id: {in: 12}},
                sort: {order: ASC, fields: [category_id, order]}
            ) {
                nodes {
                    category_id
                    id
                    answer
                    question
                }
            }
        }
    `);

    const [faq, setFaq] = useState({
        list: FaqsData.allFaqs.nodes
    });
  useEffect(() => {
      const handleXs = () => {
          const isXs = window.innerWidth <= 768;
          setXs(isXs);
      };

      window.addEventListener('resize', handleXs);

      handleXs();

      return () => {
          window.removeEventListener("resize", handleXs);
      };
  }, []);

  const slickSettings = {
      centerMode: false,
      slidesToShow: 5,
      dots: true,
      arrows: false,
      responsive: [
          {
              breakpoint: 2920,
              settings:"unslick"
          },
          {
              breakpoint: 992,
              settings: { slidesToShow: 3, slidesToScroll: 3, infinite: false }
          },
          {
              breakpoint: 768,
              settings: { slidesToShow: 2, slidesToScroll: 2, infinite: false }
          },
          {
              breakpoint: 576,
              settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
          }
      ]
  };

    useEffect(() => {
        apiRequest
            .get("/v1/faq/12")
            .then((res) => {
                const data = []
                res.data.data.forEach(d => {
                    data.push({
                        question: d.question,
                        answer: d.answer
                    })

                })
                setFaq((old) => ({...old, list: data}));
            })
            .catch((err) => {
                console.log(err);
            })
    }, [setFaq])

  const page = (
    <>
        <Helmet>

            <script type="application/ld+json">
                {`
                    {
                        "@context": "https://schema.org",
                        "@type": "VideoObject",
                        "name": "¿Qué son las Opciones?",
                        "description": "Las opciones financieras son instrumentos que se comercializan en el mercado de capitales y pertenecen al grupo de valores negociables que se denominan derivados financieros.",
                        "thumbnailUrl": "https://www.youtube.com/watch?v=QyBBzLi441g",
                        "uploadDate": "2022-07-08",
                        "duration": "PT0M55S",
                        "contentUrl": "https://balanz.com/inversiones/opciones/",
                        "embedUrl": "https://balanz.com/inversiones/opciones/"
                    }
                `}
            </script>
        </Helmet>
        <ProductBanner
          bannerContainerClassName="bannerOpciones"
          bannerTrasparecy="bannerMask"
          productTitle="Opciones"
          bannerImage={Banner}
          bannerImageClassName="ocionesBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerText1"
          bannerImageMobile={Banner}
          mobile={false}
        />
    <SalWrapper>
        <section className="fullwidth product-description">
            <Container>
                <Row className="">
                    <h2
                        data-sal="fade"
                        data-sal-delay="500"
                        data-sal-duration="400"
                    >¿Qué son las Opciones?</h2>
                </Row>
            </Container>
            <div className="bg-lt-grey"
                 data-sal="fade"
                 data-sal-delay="100"
                 data-sal-duration="200"
            >
                <Container>
                    <Row className="pb-5 pb-sm-5 pb-md-4 pb-lg-5">
                        <Col xs={12} >
                            <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Las opciones financieras son instrumentos que se comercializan en el mercado de capitales y pertenecen al grupo de valores negociables que se denominan <strong>derivados financieros</strong>, ya que su origen deriva o depende de la existencia de otro activo que se lo identifica como <strong>subyacente</strong>.<br />
                                Los subyacentes pueden ser, por ejemplo: acciones, bonos, índices, commodities, metales, tasas de interés, etc.
                            </p>
                            <div className="pt-4 text-center"
                                 data-sal="fade"
                                 data-sal-delay="300"
                                 data-sal-duration="300"
                            >
                                <Button id="opciones_invertir_1" variant="secondary" text="Invertir en Opciones" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
            <Container
                data-sal="fade"
                data-sal-delay="100"
                data-sal-duration="200"
            >
                <Row className="py-5 justify-content-center">
                    <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                        <IframeVIdeo
                            src="https://www.youtube.com/embed/QyBBzLi441g"
                            resolutionClass={null}
                        />
                    </Col>
                </Row>
                <Row className="pb-1">
                    <Col xs={12} >
                        <p className="product-text pt-2 pt-sm-3 pt-md-4 pt-lg-5">Es un contrato entre dos partes, una parte compradora y la otra vendedora. La parte compradora adquiere un derecho, la parte vendedora adquiere una obligación. Los derechos que se comercializan con este tipo de activos corresponden a la compra o venta del subyacente. El precio que se pacta se denomina precio de ejercicio, precio base o strike.
                        </p>
                    </Col>
                </Row>

            </Container>
        </section>
        <section className="fullwidth operaciones">
            <Container>
                <Row>
                    <h2 className="mb-2">¿Qué operaciones se pueden realizar con opciones?</h2>
                    <h3 className="my-5 primary-color">Existen 4 operaciones con opciones financieras:</h3>
                    <SlickProduct
                        slickSettings={slickSettings}
                        list={slick_items2}
                        customClass={'wrappeditems'}
                    />
                </Row>
                <Row className="pt-3">
                    <Col xs={12}>
                        <h4 className="mt-5 primary-color"
                            data-sal="fade"
                            data-sal-delay="0"
                            data-sal-duration="200"
                        >Opción de compra o call:</h4>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="100"
                           data-sal-duration="200"
                        >
                            Mediante el pago de una prima otorga a su comprador el derecho, no la obligación, de comprar un activo a un precio determinado hasta la fecha de ejercicio del contrato o fecha de vencimiento.
                            El comprador adquiere los derechos y paga una prima por ello.
                            El vendedor tiene la obligación de entregar el subyacente y cobra una prima por ello.
                            Si es ejercido, el vendedor de un Call se obliga a vender en los términos del contrato.

                        </p>
                        <h4 className="mt-5 primary-color"
                            data-sal="fade"
                            data-sal-delay="200"
                            data-sal-duration="200"
                        >Opción de venta o put:</h4>
                        <p className="base-text"
                           data-sal="fade"
                           data-sal-delay="300"
                           data-sal-duration="200"
                        >
                            Mediante el pago de una prima otorga a su comprador el derecho, no la obligación, de vender un activo a un precio determinado hasta la fecha de ejercicio del contrato o fecha de vencimiento.
                            El comprador adquiere los derechos y paga una prima por ello.
                            El vendedor adquiere la obligación de comprar el subyacente y cobra una prima por ello.
                            Si es ejercido, el vendedor de un Put se obliga a comprar en los términos del contrato.
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
        <section className="fullwidth razones">
             <Container>
                 <Row>
                    <h2 className="mt-2"
                        data-sal="fade"
                        data-sal-delay="0"
                        data-sal-duration="400"
                    >5 razones para invertir en Opciones</h2>
                  <SlickProduct
                    slickSettings={slickSettings}
                    list={slick_items}
                  />
                </Row>
             </Container>
         </section>
        {/*<section className="fullwidth como-operar"
                 data-sal="fade"
                 data-sal-delay="0"
                 data-sal-duration="400"
        >
             <Container>
                <Row className="gx-5 py-3 py-md-4 py-lg-5">
                  <Col xs={12} md={6}>
                    <h2 className="mt-2">¿Cómo invertir en Opciones?</h2>
                      <p className="product-text me-0 me-sm-1 me-md-3 me-lg-4 me-xl-5 mb-2 mb-lg-5">
                          Es muy fácil, lo hacés directamente desde nuestra
                          plataforma. <br/>Te recomendamos conocer tu perfil de
                          inversor y analizar junto con tu asesor, si es necesario,
                          cuál es la mejor opción para vos.</p>
                      <div className="product-cta text-start text-md-start pb-5 pb-md-0 pt-2 pt-sm-3 pt-md-4 pt-lg-5">
                          <Button variant={`secondary ${xs ? 'chevron-right': ''}`}  text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                      </div>

                  </Col>
                  <Col xs={12} md={6} className="d-lg-flex justify-content-lg-end align-items-center">
                    <IframeVIdeo
                      src="https://www.youtube.com/embed/atsav48P8_o"
                      resolutionClass={null}
                    />
                  </Col>
                </Row>
                 <Row className="d-block d-md-none">
                     <div className="text-center pt-4 p-lg-3">
                         <Button variant="secondary" text="Invertir" onClick={() => { window.open("https://clientes.balanz.com/", "_blank") }} />
                     </div>
                 </Row>
             </Container>
        </section>*/}
     <section className="fullwidthbg pack-de-cedears-banner-2 py-4 py-md-5">
            <Container className="d-flex align-items-center justify-content-center">
                   <Row className="d-flex align-items-strech py-3 py-sm-4 py-md-4 gx-5 w-100 ">
                    <Col xs={12} md={12}>
                        <div className="section-content banner2-Title text-center"
                             data-sal="slide-up"
                             data-sal-delay="0"
                             data-sal-duration="200"
                        >
                            <h3 className="titulo me-3 me-md-3 me-lg-4 me-xl-5 mb-4" ><span className="tit-54"><strong>¿Querés empezar a invertir en Opciones? </strong></span></h3>
                            
                            <span className=" subtitle  me-3 me-md-3 me-lg-4 me-xl-5" ><span className="tit-54">Abrí hoy tu cuenta de inversión en Balanz, sin costo de apertura ni mantenimiento, y accedé al mejor asesoramiento del mercado.</span></span>
                            
                        </div>
                        
                    </Col>
                    <div className="cta mt-5 mb-2 text-center pt-4 p-lg-3"
                             data-sal="slide-up"
                             data-sal-delay="300"
                             data-sal-duration="200"
                        >   
                            <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1382">
                                <Button variant="secondary" text="Abrir Cuenta"/>
                            </Link>

                            
                        </div> 

                  </Row>
            </Container>
        </section>
        <section className="fullwidth preguntas-frecuentes">
            <Container>
                <Row>
                  <Faq faq={faq} customClass={'opciones-faq'} />
                </Row>
              </Container>
        </section>
    </SalWrapper>
    </>
  )

    return (
        <main>
            <Layout title={pageTitle} description={pageDesc} childrem={page} category={"opciones"}></Layout>
        </main>
    );
};

// Step 3: Export your component
export default OpcionesPage;
